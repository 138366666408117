import { Link } from 'gatsby'
import React from 'react'

const Footer = () => (
  <footer className='bg-sky-900 py-2 md:py-4 w-full'>
    <div className='max-w-7xl w-full mx-auto flex items-center justify-between'>
        <div className='flex items-center'>
            <Link to="/" className='flex items-center space-x-2'>
                <img src="https://rtrk.kz/qazaqstan.png" className='w-24' alt="Qazaqstan TV" />
                <div className='border-l border-white font-medium text-sm text-white pl-2'>International Distribution</div>
            </Link>
        </div>
        <div className='flex text-gray-200 text-sm'>
          <span>© All rights reserved</span>
        </div>
    </div>
  </footer>
)

export default Footer
