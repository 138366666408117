// Based on
// https://www.ryanbateman.space/blog/tutorial-gatsbyjs-for-drupalers-or-how-to-jamstack-ify-your-drupal-site-with-gatsbyjs/
import React from 'react'
import { Link } from 'gatsby'
import { FaInfoCircle } from 'react-icons/fa';

const Product = ({ slug, title, info, poster, category }) => (
  <div className="w-full group">
    <Link to={slug} className="relative rounded-xl mb-2 block aspect-img overflow-hidden">
        <img src={poster} className="rounded-xl absolute inset-0 w-full h-full object-cover" alt={title} />
        <div className='absolute inset-0 w-full h-full z-10 opacity-0 hover:opacity-100 transition ease-in-out duration-300 rounded-xl'>
            <div className='absolute inset-0 w-full h-full bg-black opacity-50 z-10 rounded-xl'></div>
            <div className='absolute inset-0 w-full h-full z-20 flex items-center justify-center text-5xl text-red-600'>
                <FaInfoCircle />
            </div>
        </div>
    </Link>
    <div className='mb-2 w-full flex justify-between items-start'>
        <h2 className='font-bold text-xl group-hover:text-red-600'>{title}</h2>
        {info && <p className="text-xs pt-2">{info}</p>}
    </div>
    <div className='flex w-full space-x-2 text-xs text-gray-800 mb-2'>{category.map((cat, i) => {return (React.createElement('div', {className: 'px-2 py-1 rounded bg-sky-100'}, cat))})}</div>
  </div>
)

export default Product