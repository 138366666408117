import { Link } from 'gatsby'
import React from 'react'

const Header = () => (
  <header className='bg-sky-500 py-2 md:py-4 w-full'>
    <div className='max-w-7xl w-full mx-auto flex items-center justify-between'>
        <div className='flex items-center'>
            <Link to="/" className='flex items-center space-x-4'>
                <img src="https://rtrk.kz/qazaqstan.png" className='w-48' alt="Qazaqstan TV" />
                <div className='border-l-2 border-white font-bold text-lg text-white pl-4'>International Distribution</div>
            </Link>
        </div>
        <nav className='flex space-x-4'>
            <Link to='/tv-series' className='text-white hover:text-sky-300 font-medium' activeClassName="text-sky-300">TV Series</Link>
            <Link to='/documentaries' className='text-white hover:text-sky-300 font-medium' activeClassName="text-sky-300">Documentaries</Link>
            <Link to='/for-kids' className='text-white hover:text-sky-300 font-medium' activeClassName="text-sky-300">For Kids</Link>
            <Link to='/other' className='text-white hover:text-sky-300 font-medium' activeClassName="text-sky-300">Other</Link>
        </nav>
    </div>
  </header>
)

export default Header
